<template>
	<div id="app">
		<v-alert dense class="alert" :value="tipsInfo" :type="tipsInfoType">{{ tipsInfoContent }}</v-alert>
		<v-main>
			<!-- 上布局 -->
			<v-row>
				<v-col>
					<!-- 上布局内容 -->
					<component class="mr-6" is="top" :is_choice='is_choice' :triz_toole='triz_toole' @change='updateTool' @saveTools='saveTools' @showHome='showHome'/>
				</v-col>
			</v-row>

			<!-- 下布局 -->
			<v-row>
				<!-- 左侧 -->
				<v-col :cols="show_left?show_left_right?2:1:0" style="display: flex;">
					<v-col :cols='show_left_right?3:12' style="max-width: 72px; margin: 0px;padding: 0px;">
						<v-card :height="800" :width="72">
							<v-col align="center" v-for="item in menu_left[currentRouteName]" :key="item.name"
								:style="`${item.component==menu_left_component?'background-color: aliceblue;':''}`"
								@click="menuLeft(item.component)">
								<v-icon color="#1976d2" style="width: 100%;">{{item.icon}}</v-icon>
								<span>{{item.name}}</span>
							</v-col>
						</v-card>
					</v-col>
					<v-col cols='9' style="margin: 0px;padding: 0px;" v-if="show_left_right">
						<v-card :height="800" v-if="menu_left_component=='MyList'">
							<v-card-title>
								打开记录
								<v-icon right @click="newProject" style="position: absolute;right: 15px;"
									color="#1976d2">mdi-plus-box-outline</v-icon>
							</v-card-title>
							<v-divider></v-divider>
							<v-card-text>
								<div class="tab_content">
									<div style="width: 100%;" v-if="selectedTools.length>0">
										已选择{{selectedTools.length}}条
										<v-icon color="#1976d2" style="position: absolute;right: 20px;"
											@click="deleteToolsAll">mdi-trash-can-outline</v-icon>
									</div>
									<v-card v-for="(item, index) in recordList" :key="index" align="center"
										elevation="2" class="my-2 card_item" max-width="75" @click="switchPjo(item)"
										@mouseover="startShowDel(item.toolsId)" @mouseout="startHideDel">
										<v-img v-if="item.image" height="56" :src="item.image"></v-img>
										<v-img v-else height="56" src="../../assets/defaultImg.png"></v-img>
										<v-card-title style="font-size: 14px;padding: 0px 3px;"
											v-if="item.toolsName">{{ item.toolsName }}</v-card-title>
										<div class="overlay"  v-if="overlay_showId==item.toolsId || showoverlay">
											<v-checkbox @click.stop v-model="selectedTools" :value="item.toolsId"
												style="padding: 0px;margin: 3px;"></v-checkbox>
										</div>
									</v-card>
								</div>
								<!-- <v-treeview :open.sync="open" :items="categoryList" item-key="id" open-on-click
									return-object activatable transition dense @update:active="updateActive">
									<template v-slot:prepend="{ item, open }">
										<v-icon
											v-if="item.id==currentRouteName">{{ open ? 'mdi-folder-open' : 'mdi-folder' }}</v-icon>
										<v-img v-if="item.image" :src="item.image"
											style="width: 25px;height: 25px;"></v-img>
										<v-icon v-if="!item.image && item.id!=currentRouteName">mdi-tools</v-icon>
									</template>
									<template v-slot:label="{ item }">
										{{ item.name }}
									</template>
									<template v-slot:item-children="{ item }">
										<v-treeview :items="item.children" @update:active="updateActive"></v-treeview>
									</template>
								</v-treeview> -->
							</v-card-text>
						</v-card>
						<v-card :height="800" v-else-if="menu_left_component=='ReasonsResolves'">
							<v-row >
								<v-col align="center">
									<v-btn class="ma-2" outlined color="#1976d2" style="border-radius: 20px;" @click="addClipResolve">
										<v-icon left>mdi-plus</v-icon> 添加解决方案
									</v-btn>
								</v-col>
								
							</v-row>
							<v-row v-for="(reason,i) in triz_toole.reasonsResolves" :key="reason.id">
								<v-col align="center">
									<span>解决方案{{i+1}}</span>
									<v-btn dense class="ma-2" tile outlined style="border-radius: 20px;" color="red" @click="removeClipResolve(j)">
										<v-icon left>mdi-minus</v-icon> 删除
									</v-btn>
								</v-col>
							</v-row>
						</v-card>
						<v-card :height="800" v-else>
						</v-card>
					</v-col>

					<div class="p-editor-btn">
						<div class="p-editor-btn-bg">
							<img src="@/assets/collapse-btn-light.svg" width="39" height="620"
								style="pointer-events: none;">
						</div>
						<div class="p-editor-btn-icon" @click="show_left_right=!show_left_right">
							<v-icon color="#1976d2" v-if="show_left_right">mdi-chevron-left</v-icon>
							<v-icon color="#1976d2" v-if="!show_left_right">mdi-chevron-right</v-icon>
						</div>
					</div>
				</v-col>
				<!-- 中间 -->
				<v-col :cols="show_left?show_right?show_left_right?8:9:10:show_right?10:12">
					<v-card height="800" style="overflow: auto" v-if="is_choice">
						<!-- 中间内容 -->
						<component v-for="sub in triz_toole_list[currentRouteName]" class="mr-6" :is="sub.component" :triz="triz_toole"
							:key="`${sub.component}-${new Date().getTime()}`" @change="change" />
					</v-card>
					<v-card height="800" style="overflow: auto" v-if="!is_choice">
						<!-- 中间内容 -->
						<welcomePage class="mr-6" @updatePjo='updatePjo' @newProject='newProject' :cardList='recordList'
							:router="currentRouteName" />
					</v-card>
				</v-col>
				<!-- 右侧 -->
				<v-col :cols="show_right?2:0">
					<!-- 右侧内容 -->
					<v-card v-show="show_right" height="800" style="overflow: auto">
						<v-card flat class="zdy_bjt" style="">
							<v-row style="height: 90px;">
								<v-col cols="12" elevation="1">
									<img src="../../assets/triz.png" style="width: 100%; margin-left: -22%;" />
									<v-divider class="mx-4 mb-1 " style="margin-top: -70px;"></v-divider>
								</v-col>
							</v-row>
							<v-card height='700' style=" overflow: auto;background: rgba(255, 255, 255, 0.0);" flat>
								<v-col ols="12">
									<v-card elevated='20' style="background: rgba(167, 167, 167, 0.1);"
										v-for="item in variants[0].val" :key="item.path"
										v-if="item.path==currentRouteName">
										<v-col ols="12">
											<span>{{item.caption}}</span>
										</v-col>
									</v-card>
									<v-card elevated='20' style="background: rgba(167, 167, 167, 0.1);"
										v-for="item in variants[1].val" :key="item.path"
										v-if="item.path==currentRouteName">
										<v-col ols="12">
											<span>{{item.caption}}</span>
										</v-col>
									</v-card>
								</v-col>
							</v-card>
						</v-card>
					</v-card>
				</v-col>
			</v-row>
		</v-main>
		<!-- 新建项目 start -->
		<v-dialog v-model="new_dialog" persistent width="1024">
			<v-card>
				<v-card-title>
					<span class="text-h5">添加工具</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12" sm="6" md="6">
								<v-text-field label="项目名称*" v-model="addToolsSin.toolsName" required></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="6">
								<v-text-field label="项目描述" v-model="addToolsSin.toolsDescription"></v-text-field>
							</v-col>
							<v-col cols="12">
								<UploadFile @getImg="UploadFile" :src="addToolsSin.image" title="项目图标" />
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue-darken-1" variant="text" @click="new_dialog = false">
						关闭
					</v-btn>
					<v-btn color="blue-darken-1" variant="text" @click="insertTools">
						保存
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- 新建项目 end -->
	</div>
</template>

<script>
	import uploadFile from '@/components/uploadFile.vue'
	import welcomePage from './component/welcomePage.vue'
	import FunctionalComponent from './component/FunctionalComponent.vue'
	import FunctionalEffect from './component/FunctionalEffect.vue'
	import FunctionalModel from './component/FunctionalModel.vue'
	import FunctionalModelDiagram from './component/FunctionalModelDiagram.vue'
	import Reason from './component/Reason.vue'
	import Reason_tab from './component/Reason_tab.vue'
	import GuideSearch from './component/GuideSearch.vue'
	import Clip from './component/Clip.vue'
	import KeyProblem from './component/KeyProblem.vue'
	import StandardResovle from './component/StandardResovle.vue'
	
	import top from "./component/top.vue"
	import {
		getProject,
	} from '@/api/project'
	import {
		showTools,
		insertTools,
		delTools
	} from '@/api/account'
	import {
		defaultTriz,
		menu_left,
		variants,
		triz_toole_list
	} from '@/store/default.js'
	export default {
		components: {
			top,
			welcomePage,
			FunctionalComponent,
			FunctionalEffect,
			FunctionalModel,
			FunctionalModelDiagram,
			Reason,
			Reason_tab,
			GuideSearch,
			Clip,
			KeyProblem,
			StandardResovle,
			UploadFile: uploadFile,
		},
		data: () => ({
			overlay_showId: '',
			showoverlay: false,
			is_choice: false,
			tipsInfo: false,
			tipsInfoType: 'success',
			tipsInfoContent: '',
			show_left: true,
			show_right: true,
			show_left_right: true,
			open_dialog: false,
			new_dialog: false,
			selectedTools: [],
			defaultTriz:defaultTriz,
			triz_toole: {
				...defaultTriz
			},
			menu_left: menu_left,
			timer: null,
			variants: variants,
			menu_left_component: 'MyList',
			currentRouteName: '',
			triz_toole_list: triz_toole_list,
			addToolsSin: {
				toolsId: '',
				toolsType: '',
				toolsName: '',
				toolsDescription: '',
				image: '',
				jsonContent: {
					...defaultTriz
				},
			},
			openToolsSin: {
				toolsId: '',
				toolsType: '',
				toolsName: '',
				toolsDescription: '',
				image: '',
				jsonContent: {
					...defaultTriz
				},
			},
			recordList: [],
			// categoryList: [{
			// 	id: '',
			// 	name: '标准解',
			// 	icon: '',
			// 	children: []
			// }],
		}),
		computed: {
			triz() {
				return this.$store.state.triz
			},
		},
		created() {
			this.currentRouteName = this.$route.name;
			this.addToolsSin.toolsType = this.$route.name;
			// this.categoryList[0].id = this.$route.name;
			this.showToolslist();
		},
		watch: {
			triz_toole() {
				this.is_choice = true
			},
			selectedTools() {
				if (this.selectedTools.length > 0) {
					this.showoverlay = true
				} else {
					this.showoverlay = false
				}

			}

		},
		mounted() {
			window.addEventListener('hashchange', this.customFunction, false);
			window.addEventListener('popstate', this.customFunction, false);
		},
		beforeDestroy() {
			window.removeEventListener('hashchange', this.customFunction, false);
			window.removeEventListener('popstate', this.customFunction, false);
		},
		methods: {
			customFunction() {
				console.log('发生跳转');
				this.$router.go(0);

			},
			updateTool(data){
				this.triz_toole=data
			},
			startShowDel(toolsId) {
				clearTimeout(this.timer); // 清除之前的定时器
				this.timer = setTimeout(() => {
					this.showDel(toolsId);
				}, 200); // 设置延迟时间为200毫秒
			},
			startHideDel() {
				clearTimeout(this.timer); // 清除之前的定时器
				this.timer = setTimeout(() => {
					this.hideDel();
				}, 200); // 设置延迟时间为200毫秒
			},
			showDel(toolId) {
				this.overlay_showId = toolId
			},
			hideDel() {
				this.overlay_showId = ""
			},
			// 打开已有项目
			openProject() {
				this.open_dialog = true;
			},
			// 左侧菜单点击事件
			menuLeft(con) {
				this.menu_left_component = con
				this.show_left_right = true
			},
			// 记录点击事件
			async switchPjo(item) {
				const ok = await this.$dialog.confirm({
					text: '是否确认切换项目？',
					title: '提示',
					actions: {
						false: '不',
						true: '是的',
					},
				})
				if (!ok) return
				this.updatePjo(item)
			},
			// 新建一个项目
			newProject() {
				this.addToolsSin.toolsId = ""
				this.addToolsSin.toolsName = ""
				this.addToolsSin.toolsDescription = ""
				this.addToolsSin.image = ""
				this.new_dialog = true
			},
			UploadFile(e) {
				this.addToolsSin.image = e
			},
			// 保存工具
			insertTools() {
				insertTools(this.addToolsSin)
					.then((response) => {
						let data = response.data
						this.openToolsSin = data
						this.$store.commit('updateTriz', data.jsonContent)
						this.recordList.push(data)
						this.triz_toole = data.jsonContent
						this.new_dialog = false
						this.tipsInfo = true
						this.tipsInfoType = 'success'
						this.tipsInfoContent = '新曾成功'
						setTimeout(() => {
							this.tipsInfo = false
						}, 2000)
					})
					.catch((error) => {
						let err = error.response.data.message
						this.new_dialog = false
						this.tipsInfo = true
						this.tipsInfoType = 'error'
						this.tipsInfoContent = err
						setTimeout(() => {
							this.tipsInfo = false
						}, 2000)
					})
			},
			// 保存工具
			saveTools() {
				insertTools(this.openToolsSin)
					.then((response) => {
						this.new_dialog = false
						this.tipsInfo = true
						this.tipsInfoType = 'success'
						this.tipsInfoContent = '保存成功'
						setTimeout(() => {
							this.tipsInfo = false
						}, 2000)
					})
					.catch((error) => {
						let err = error.response.data.message
						this.new_dialog = false
						this.tipsInfo = true
						this.tipsInfoType = 'error'
						this.tipsInfoContent = err
						setTimeout(() => {
							this.tipsInfo = false
						}, 2000)
					})
			},
			//获取组织信息
			showToolslist() {
				let that = this
				showTools(this.currentRouteName)
					.then((response) => {
						let data = response.data
						that.recordList = data
						// data.forEach((res) => {
						// 	res.id = res.toolsId
						// 	res.name = res.toolsName
						// 	res.icon = ''
						// 	console.log('data', res)
						// 	that.categoryList[0].children.push(res)
						// })
					})
					.catch(() => {})
			},
			deleteToolsAll() {
				let that = this
				
				delTools(this.selectedTools)
					.then((response) => {
						that.tipsInfo = true
						that.tipsInfoType = 'success'
						that.tipsInfoContent = '删除成功'
						that.selectedTools.forEach(id=>{
							that.recordList=that.recordList.filter(item=>item.toolsId!=id)
						})
						that.selectedTools=[]
						setTimeout(() => {
							that.tipsInfo = false
						}, 2000)
					})
					.catch(() => {})
			},
			// 子页面点击
			updatePjo(item) {
				this.is_choice = true
				this.$store.commit('updateTriz', item.jsonContent)
				this.openToolsSin = item
				this.triz_toole = item.jsonContent
			},
			// 跳转到首页
			showHome() {
				this.is_choice = false
			},
			addClipResolve() {
				const id = `clip-${new Date().getTime()}`
				if (this.triz_toole.reasonsResolves) {
					this.triz_toole.reasonsResolves.push({
						id,
						solutionDetail: '',
					})
				} else {
					this.triz_toole.reasonsResolves = [{
						id,
						solutionDetail: '',
					}]
				}
				this.triz_toole.solutionScores.push({
					id,
				})
				this.change(this.triz_toole)
			},
			removeClipResolve(i) {
				const [{
					id
				}] = this.triz_toole.reasonsResolves.splice(i, 1)
				this.triz_toole.solutionScores.splice(
					this.triz_toole.solutionScores.findIndex((ele) => ele.id === id),
					1
				)
				this.change(this.triz_toole)
			},
			change(data) {
				// console.log('CausalChain',data)
				this.$store.commit('updateTriz', data)
				this.$store.commit('do', data)
				this.triz_toole = data
				// this.saveTools()
			},
		},
	}
</script>


<style lang="scss">
	@import "../../styles/one_triz/index.scss";
	@import "../../styles/new_triz/index.scss";
</style>